import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useDispatch, useSelector } from 'react-redux';
import AddEditAdminUserDialog from '../components/add_edit_admin_user_dialog';
import { getAdminsUsers } from '../../../../redux/admin_users/admin_users_slice';




export default function AdminUsersScreen() {

    const adminUsers = useSelector((state) => state.adminUsers);
   const {loading,data:users}=adminUsers;
    const [showAddUserDialog,setAddUserDialog]=useState(false);
    const [editUser,setEditUser]=useState(null);

    const dispatch = useDispatch();

    const dialogFuncMap = {
        'showAddUserDialog': setAddUserDialog,
     
    }
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

      
    }


    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    useEffect(() => {
        dispatch(getAdminsUsers());

    }, [])


    const [loading1, setLoading1] = useState(false);

    const statuses = [
        'active', 'blocked'
    ];



    const [dropdownItem, setDropdownItem] = useState(null);
    const dropdownItems = [
        { name: 'Option 1', code: 'Option 1' },
        { name: 'Option 2', code: 'Option 2' },
        { name: 'Option 3', code: 'Option 3' }
    ];

    // const formatDate = (value) => {
    //     return value.toLocaleDateString('en-US', {
    //         day: '2-digit',
    //         month: '2-digit',
    //         year: 'numeric',
    //     });
    // }
    // const statusFilterTemplate = (options) => {
    //     return <Dropdown value={options.status} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
    // }

    // const dateBodyTemplate = (rowData) => {
    //     return formatDate(rowData.createdAt);
    // }

    const statusBodyTemplate = (rowData) => {
        
        return <span>{rowData.status?'Active':'Inactive'}</span>;
    }

    const actionsBodyTemplate = (rowData) => {
        return <div>
                            <Button icon="pi pi-user-edit" className="p-button-rounded p-button-primary" onClick={()=>{
                                setEditUser(rowData);
                                onClick('showAddUserDialog');
                            }} aria-label="Edit" />
                            {/* <Button icon="pi pi-bookmark" className="p-button-rounded p-button-secondary" aria-label="Bookmark" /> */}

        </div>;
    }
    return (


        <>

            <Dialog header={editUser==null?'Add User':'Edit User'} visible={showAddUserDialog} onHide={() => onHide('showAddUserDialog')}  breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }}>
                <AddEditAdminUserDialog editUser={editUser} onHide={()=>onHide('showAddUserDialog')} />
            </Dialog>
            <div className="card">
                <div className='flex flex-row justify-content-between align-content-center align-items-center pb-3'>
                    <h5>Admin Users</h5>
                    <Button label='Add new' className='p-button-success p-button-rounded p-button-sm' onClick={() =>{
                           setEditUser(null);
                        onClick('showAddUserDialog')}} />



                </div>

                <DataTable value={users}  paginator className="p-datatable-gridlines" showGridlines rows={10}
                    dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll"
                    emptyMessage="No customers found.">
                    <Column field="name" header="Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="email" header="Email" filter filterPlaceholder="Search by email" style={{ minWidth: '12rem' }} />
                    <Column field="mobile" header="Mobile" filter filterPlaceholder="Search by mobile" style={{ minWidth: '12rem' }} />
                    <Column field="role.role" header="Role" filter filterPlaceholder="Search by role" style={{ minWidth: '12rem' }} />

                    {/* <Column header="Date" field="createdAt" dataType="date" style={{ minWidth: '10rem' }}
                        filter /> */}
                    <Column field="status" header="Status" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={statusBodyTemplate} filter />
                    <Column  header="Actions" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={actionsBodyTemplate}  />
                </DataTable>
            </div>
        </>

    )
}

//id, name , role, email, phone, status, last login
