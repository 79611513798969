import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { getCompaniesList } from '../../../../redux/companies/companies_slice';
import AddEditCompanyDialog from '../components/add_edit_company_dialog';

export default function CompaniesScreen() {


    const history=useHistory();

    const companySlice=useSelector((state)=>state.companySlice);
    const {data,loading,error}=companySlice;

    const [showAddEditDialog,setAddEditDialog]=useState(false);
    const [editCompany,setEditCompany]=useState(null);

    const dispatch = useDispatch();

    const dialogFuncMap = {
        'showAddEditDialog': setAddEditDialog,
     
    }
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

      
    }


    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    useEffect(() => {
        dispatch(getCompaniesList());

    }, [])

    const actionsBodyTemplate = (rowData) => {
        return <div>
                            <Button icon="pi pi-user-edit" className="p-button-rounded p-button-primary mx-2" onClick={()=>{
                                setEditCompany(rowData);
                                onClick('showAddEditDialog');
                            }} aria-label="Edit" />
                            <Button icon="pi pi-arrow-right" className="p-button-rounded p-button-primary" onClick={()=>{
                               history.push("/api/company/"+rowData._id)
                            }} aria-label="Edit" />
                            {/* <Button icon="pi pi-bookmark" className="p-button-rounded p-button-secondary" aria-label="Bookmark" /> */}

        </div>;
    }

    
  return (
    <>
      <Dialog header={editCompany==null?'Add Company':'Edit Company'} visible={showAddEditDialog} onHide={() => onHide('showAddEditDialog')}  breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }}>
                <AddEditCompanyDialog editUser={editCompany} onHide={()=>onHide('showAddEditDialog')} />
            </Dialog>
         <div className="card">
                <div className='flex flex-row justify-content-between align-content-center align-items-center pb-3'>
                    <h5>Companies</h5>
                    <Button label='Add new' className='p-button-success p-button-rounded p-button-sm' onClick={() =>{
                           setEditCompany(null);
                        onClick('showAddEditDialog')}} />



                </div>

                <DataTable value={data}  paginator className="p-datatable-gridlines" showGridlines rows={10}
                    dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll"
                    emptyMessage="No companies found.">
                    <Column field="name" header="Company Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="address" header="Address" filter filterPlaceholder="Search by address" style={{ minWidth: '12rem' }} />
                    <Column field="superAdmin.firstName" header="Owner first name" filter filterPlaceholder="Search by mobile" style={{ minWidth: '12rem' }} />
                    <Column field="superAdmin.lastName" header="Owner last name" filter filterPlaceholder="Search by role" style={{ minWidth: '12rem' }} />

                    {/* <Column header="Date" field="createdAt" dataType="date" style={{ minWidth: '10rem' }}
                        filter /> */}
                    <Column  header="Actions" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={actionsBodyTemplate}  />
                </DataTable>
            </div>
    
    </>
  )
}
