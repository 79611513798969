import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React, { useEffect } from 'react'
import appAssets from '../../../../constants/appAssets';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import CustomInputField from '../../../components/custom_input_field';
import { toast } from 'react-toastify';
import { loginUser } from '../../../../redux/auth_slice/login_user_slice';

export default function LoginScreen() {

  //redux
  const { success, error, loading } = useSelector((state) => state.loginUser);

  //hooks

  useEffect(() => {
    if (success !== undefined) {
      if (success === false) {
        toast.error(error)

      } else {

      }
    }
  }, [success]);

  const dispatch = useDispatch();

  //forms

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (data) => {
      dispatch(loginUser(data));

      console.log(data);

    },
  });


  const validationSchema = Yup.object().shape({

    password: Yup.string().required("Password is required.").min(8, 'Minimum length should be 8'),
    email: Yup.string().required("Email is required."),

  });


  return (
    <>
        <div className="login_body pt-5">
            {/* <button onClick={testFun}>test</button> */}
            <div align="center" style={{ marginTop: "", marginBottom: "1%" }}>
                <img src='app/logo.png' alt="" width={"10%"} />
            </div>
            <div className="flex flex-row justify-content-center">
           <div className="col-6 md:col-6\8">
           <form onSubmit={formik.handleSubmit}>

                            <div className=" p-fluid">

                                <h3>Login</h3>
                              
                <CustomInputField iden='email' formik={formik} placeHolder='Enter email' type='email' />
                <CustomInputField iden='password' formik={formik} placeHolder='Enter password' type='password' />


                <Button loading={loading} type='submit' className='customButton' label='NEXT' />
          
                              
                               
                                

                            </div>
                            </form>

                        </div>
         
               
            </div>
        </div>


      

    </>
  )
}
