import Axios from 'axios';
import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const branchBudgetSlice = createSlice({
    name: 'budgetSlice',
    initialState: {},
    reducers: {
        resetAddEditBudget(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined ,budgetSources:undefined }
        }

    },
    extraReducers: (builder) => {
        // builder
        //     .addCase(getCompaniesList.pending, (state, action) => {
        //         return { ...state, loading: true }
        //     })
        //     .addCase(getCompaniesList.fulfilled, (state, action) => {

        //         return { ...state, loading: false, data: action.payload }
        //     })
        //     .addCase(getCompaniesList.rejected, (state, action) => {

        //         return {
        //             ...state,
        //             loading: false,
        //             error: action.payload
        //         }
        //     });

        builder
            .addCase(addBudget.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addBudget.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload, addSuccess: true }
            })
            .addCase(addBudget.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess: false
                }
            });
        builder
            .addCase(editBudget.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editBudget.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload, editSuccess: true }
            })
            .addCase(editBudget.rejected, (state, action) => {

                return {
                    ...state,
                    editLoading: false,
                    editError: action.payload,
                    editSuccess: false
                }
            });

        builder
            .addCase(getBudgetSources.pending, (state, action) => {
                return { ...state, budgetSoucesLoading: true }
            })
            .addCase(getBudgetSources.fulfilled, (state, action) => {
                console.log(action.payload)

                return { ...state, budgetSoucesLoading: false, budgetSources: action.payload }
            })
            .addCase(getBudgetSources.rejected, (state, action) => {

                return {
                    ...state, budgetSoucesLoading: false,
                    budgetSourceError: action.payload,
                }
            });
        builder
            .addCase(getBranchBudget.pending, (state, action) => {
                return { ...state, budgetLoading: true }
            })
            .addCase(getBranchBudget.fulfilled, (state, action) => {

                return { ...state, budgetLoading: false, branchBudget: action.payload }
            })
            .addCase(getBranchBudget.rejected, (state, action) => {

                return {
                    ...state, budgetLoading: false,
                    budgetError: action.payload,
                }
            });
    },
});

export default branchBudgetSlice.reducer;
export const { resetAddEditBudget } = branchBudgetSlice.actions;


// Thunks
// export const getCompaniesList = createAsyncThunk('company/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
//     try {
//         const { data } = await Axios.get(appUrl.baseUrl + appUrl.getAdminUsers);
//         return fulfillWithValue(data.data);
//     } catch (error) {

//         throw rejectWithValue(error.response && error.response.data.msg
//             ? error.response.data.msg
//             : error.message)

//     }

// });
export const addBudget = createAsyncThunk('addBudget/fetch', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        console.log(body);
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addBudget, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const editBudget = createAsyncThunk('editBudget/fetch', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        console.log(body);
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.addBudget, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const getBranchBudget = createAsyncThunk('budget/get', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.get(appUrl.baseUrl + appUrl.getBranchBudget + `?branchId=${id}`);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const getBudgetSources = createAsyncThunk('budget/sources', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        let url = appUrl.baseUrl + appUrl.getBudgetSources + `?branchId=${id}`;
        console.log(url)
        const { data } = await axiosApi.get(url);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
