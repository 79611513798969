
import { configureStore } from '@reduxjs/toolkit'
import adminUsersSlice from './redux/admin_users/admin_users_slice';
import login_user_slice from './redux/auth_slice/login_user_slice';
import budget_slice from './redux/companies/budget_slice';
import companies_slice from './redux/companies/companies_slice';
import super_admin_slice from './redux/super_admin/super_admin_slice';


const user = localStorage.getItem("user");

const initialState = {
  loginUser: {
    user: user === null ? undefined : JSON.parse(user)
  }
};






const store = configureStore({
  reducer: {
    adminUsers: adminUsersSlice,
    loginUser: login_user_slice,
    companySlice:companies_slice,
    budgetSlice:budget_slice,
    superAdminSlice:super_admin_slice
  },
  preloadedState: initialState
},
)



export default store;

