import Axios from 'axios';
import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const superAdmin = createSlice({
    name: 'superAdmin',
    initialState: {},
    reducers: {
        resetAddEditSuperAdmin(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined }
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getSuperAdminUsers.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getSuperAdminUsers.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getSuperAdminUsers.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

        builder
            .addCase(addSuperAdminUser.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addSuperAdminUser.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload ,addSuccess:true}
            })
            .addCase(addSuperAdminUser.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess:false
                }
            });
        builder
            .addCase(editSuperAdmin.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editSuperAdmin.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload,editSuccess:true }
            })
            .addCase(editSuperAdmin.rejected, (state, action) => {

                return {
                    ...state, editLoading: false,
                    error: action.payload,
                    editSuccess:false
                }
            });
    },
});

export default superAdmin.reducer;
export const { resetAddEditSuperAdmin } = superAdmin.actions;


// Thunks
export const getSuperAdminUsers = createAsyncThunk('superAdmin/fetch', async (flag, { rejectWithValue, fulfillWithValue }) => {
    try {
        let url='';
    
        if(flag){
            url=appUrl.baseUrl+appUrl.getSuperAdmins+`?flag=${flag}`;
        }else{
            url=appUrl.baseUrl+appUrl.getSuperAdmins;
        }
        const { data } = await axiosApi.get(url);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});

export const addSuperAdminUser = createAsyncThunk('superAdmin/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addSuperAdmins, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const editSuperAdmin = createAsyncThunk('superAdmin/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.editSuperAdmins, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
