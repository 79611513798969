import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getSuperAdminUsers } from '../../../../redux/super_admin/super_admin_slice';
import AddEditSuperAdminDialog from '../components/add_edit_super_admin_dialog';

export default function SuperAdminScreen() {


    const [editUser,setEditUser]=useState(null);

    const {data,loading,error}=useSelector((state)=>state.superAdminSlice);
    const [showAddUserDialog,setAddUserDialog]=useState(false);
    const dialogFuncMap = {
        'showAddUserDialog': setAddUserDialog,
     
    }
    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

    }


    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const dispatch=useDispatch();

    useEffect(() => {

        dispatch(getSuperAdminUsers("all"));
    

    }, [])
    

    const actionsBodyTemplate = (rowData) => {
        return <div>
       
            <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary" onClick={() => {
                setEditUser(rowData);
                onClick('showAddUserDialog')

                
            }} aria-label="Budget" />
            {/* <Button icon="pi pi-bookmark" className="p-button-rounded p-button-secondary" aria-label="Bookmark" /> */}

        </div>;
    }



  return (
   <>
     <Dialog header={editUser==null?'Add User':'Edit User'} visible={showAddUserDialog} onHide={() => onHide('showAddUserDialog')}  breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }}>
                <AddEditSuperAdminDialog editUser={editUser} onHide={()=>onHide('showAddUserDialog')} />
            </Dialog>
   <div className='card'>
   <div className='flex flex-row justify-content-between align-content-center align-items-center'>
                    <h5>Super Admin</h5>
                    <Button label='Add new' className='p-button-success p-button-rounded p-button-sm' onClick={() =>{
                           setEditUser(null);
                        onClick('showAddUserDialog')}} />



                </div>

   </div>

   <div className='card'>
   <DataTable value={data}  paginator className="p-datatable-gridlines" showGridlines rows={10}
                    dataKey="id" filterDisplay="menu" loading={loading} responsiveLayout="scroll"
                    emptyMessage="No customers found.">
                    <Column field="firstName" header="First Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="lastName" header="Last Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                    <Column field="email" header="Email" filter filterPlaceholder="Search by email" style={{ minWidth: '12rem' }} />
                    <Column field="company.name" header="Company name" filter filterPlaceholder="Search by mobile" style={{ minWidth: '12rem' }} />

                    {/* <Column header="Date" field="createdAt" dataType="date" style={{ minWidth: '10rem' }}
                        filter /> */}
                    <Column  header="Actions" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={actionsBodyTemplate}  />
                </DataTable>
       
       </div>                     

   
   </>
  )
}
