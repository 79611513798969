import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addBudget, editBudget, getBranchBudget, getBudgetSources, resetAddEditBudget } from '../../../../redux/companies/budget_slice';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { toast } from 'react-toastify';



export default function AddEditBudgetDialog({ editData, id: branchId, onHide }) {


    const { budgetSoucesLoading, budgetSources, addLoading, addError, addSuccess } = useSelector((state) => state.budgetSlice)

    const [values, setValues] = useState([]);
    const [gotData, setGotData] = useState(false);
    const [date, setDate] = useState("");
    const [month, setMonth] = useState("");
    const [occupiedRooms, setOccupiedRooms] = useState(0);


    const [sources, setSources] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {



        dispatch(getBudgetSources(branchId));

        return () => {
            dispatch(resetAddEditBudget());
        }



    }, [])


    useEffect(() => {

        if (editData === null) {

            if (budgetSources !== undefined) {

                setGotData(true);
               
                setValues(budgetSources.sources.map((item) => ({ ...item ,reserved1:0})));
            }
        } else {
            let vals = [];
            for (var item of editData.budget) {
                vals.push({
                    "source": item.source._id,
                    "sourceName": item.source.type,
                    "budgetedUnits": item.budgetedUnits,
                    "budgetedUnitRate": item.budgetedUnitRate,
                    "reserved1":item.reserved1
                })
            }
            setValues(vals);

            let month = editData.month.toString().length === 1 ? '0' + editData.month.toString() : editData.month;
            console.log(month);

            setMonth(`${editData.year}-${month}`)
        }



    }
        , [budgetSources]);

    useEffect(() => {
        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("Budget added");
                onHide();
              
                dispatch(getBranchBudget(branchId));
            } else {
                toast.error(addError)
            }
        }


    }, [addSuccess])

    const handleSubmit = (e) => {
        e.preventDefault();
        let body = {


        }
        let getMonth = month.split('-')[1];
        let getYear = month.split('-')[0];



        body['branch'] = branchId;
        body['budget'] = values;
        body['month'] = getMonth;
        body['year'] = getYear;
       
        if (editData === null) {
            dispatch(addBudget(body));
        } else {
            body['budgetId'] = editData._id;
            dispatch(addBudget(body));
        }






    }



    return (
        <>
            {<div>

                <form onSubmit={handleSubmit}>

                    <div className="col-12 md:col-6\8">
                        <div className=" p-fluid">


                            <div className={`field`}>
                                <label  >Month and year </label>
                                <InputText disabled={editData !== null}  onWheel={ event => event.currentTarget.blur() } onScroll={ event => event.currentTarget.blur() } onScrollCapture={event => event.currentTarget.blur()}  required name='month' type='month' value={month} onChange={(e) => {
                                    console.log(e.target.value);
                                    setMonth(e.target.value);

                                }} />

                            </div>

                               
                            {values.map((item, index) => {
                                return <div key={index}>
                                    <h4 >{item.sourceName}</h4>

                                    <div className={`field`}>
                                        <label >Budged Units</label>
                                        <InputText name='budgetdUnits' type='number'  onWheel={ event => event.currentTarget.blur() } onScroll={ event => event.currentTarget.blur() } onScrollCapture={event => event.currentTarget.blur()} value={item.budgetedUnits} onChange={(e) => {


                                            setValues((prev) => {

                                                prev[index].budgetedUnits = e.target.value;
                                                return [...prev];
                                            })
                                        }} />

                                    </div>
                                    <div className={`field`}>
                                        <label  >Budgeted Unit rate</label>
                                        <InputText name='budgetdUnitRate' min={1}  onWheel={ event => event.currentTarget.blur() } onScroll={ event => event.currentTarget.blur() } onScrollCapture={event => event.currentTarget.blur()} type='number' value={item.budgetedUnitRate} onChange={(e) => {
                                            setValues((prev) => {

                                                prev[index].budgetedUnitRate = e.target.value;
                                                return [...prev];
                                            })
                                        }} />

                                    </div>
                                  {item.source==='63832642ab7479bd3162e923'&&  <div className={`field`}>
                                        <label  >Budgeted KWH</label>
                                        <InputText name='reserved1' min={1}  onWheel={ event => event.currentTarget.blur() } onScroll={ event => event.currentTarget.blur() } onScrollCapture={event => event.currentTarget.blur()} type='number' value={item.reserved1} onChange={(e) => {
                                            setValues((prev) => {

                                                prev[index].reserved1 = e.target.value;
                                                return [...prev];
                                            })
                                        }} />

                                    </div>}





                                </div>
                            })}
                            <Button loading={false} className='customButton' label='Submit' type='submit' />

                        </div>


                    </div>


                </form>

            </div>

            }
        </>
    );
}
