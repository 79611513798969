import Axios from 'axios';
import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const adminUsersSlice = createSlice({
    name: 'adminUser',
    initialState: {},
    reducers: {
        resetAddEditAdminUser(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined }
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAdminsUsers.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getAdminsUsers.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getAdminsUsers.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

        builder
            .addCase(addAdminUser.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addAdminUser.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload ,addSuccess:true}
            })
            .addCase(addAdminUser.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess:false
                }
            });
        builder
            .addCase(editAdminUser.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editAdminUser.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload,editSuccess:true }
            })
            .addCase(editAdminUser.rejected, (state, action) => {

                return {
                    ...state, editLoading: false,
                    error: action.payload,
                    editSuccess:false
                }
            });
        builder
            .addCase(getAdminRoles.pending, (state, action) => {
                return { ...state, rolesLoading: true }
            })
            .addCase(getAdminRoles.fulfilled, (state, action) => {

                return { ...state, rolesLoading: false, roles: action.payload,rolesSuccess:true }
            })
            .addCase(getAdminRoles.rejected, (state, action) => {

                return {
                    ...state, rolesLoading: false,
                    rolesError: action.payload,
                    rolesSuccess:false
                }
            });
    },
});

export default adminUsersSlice.reducer;
export const { resetAddEditAdminUser } = adminUsersSlice.actions;


// Thunks
export const getAdminsUsers = createAsyncThunk('adminuser/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getAdminUsers);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const getAdminRoles = createAsyncThunk('roles/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getRoles);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addAdminUser = createAsyncThunk('adminuser/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.post(appUrl.baseUrl + appUrl.addAdminUser, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const editAdminUser = createAsyncThunk('adminuser/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.put(appUrl.baseUrl + appUrl.editAdminUser, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
