import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getSuperAdminUsers } from '../../../../redux/super_admin/super_admin_slice';
import CustomLoading from '../../../components/custom_spinner';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { addCompany, editCompany, getCompaniesList, resetAddEditCompany } from '../../../../redux/companies/companies_slice';
import CustomInputField from '../../../components/custom_input_field';
import { Button } from 'primereact/button';

export default function AddEditCompanyDialog({ editCompany: editData, onHide }) {
    const { data, loading, error } = useSelector((state) => state.superAdminSlice);

    const companySlice = useSelector((state) => state.companySlice);
    const { addLoading, editLoading, addError, editError, editSuccess, addSuccess } = companySlice;
    const dispatch = useDispatch();
    const [users,setUsers]=useState([]);

    useEffect(() => {
        dispatch(getSuperAdminUsers());

    }, [])

    useEffect(()=>{
        if(data!==undefined){
            let temp=data.map((item)=>{
               return {
                    "name":`${item.firstName} ${item.lastName} [${item.email}]`,
                    "value":item._id
                }
            });
            setUsers(temp);
        }
    },[data])

    useEffect(() => {


        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("company added successfully")
                formik.resetForm();
                onHide();
                dispatch(getCompaniesList());


            } else {
                toast.error(addError)


            }
        }

        if (editSuccess != undefined) {
            if (editSuccess === true) {
                toast.success("Company updated successfully");
                formik.resetForm();
                onHide();
                dispatch(getCompaniesList());


            } else {
                toast.error(editError);


            }
        }

        return () => {
            dispatch(resetAddEditCompany());
        }


    }, [addSuccess, addError, editSuccess, editError]);


    const validationSchema = Yup.object().shape({
        name: Yup.string().required("This field is required."),
        address: Yup.string().required("This field is required."),
        superAdmin: Yup.string().required("This field is required."),

    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            address: "",
            superAdmin: "",

        },

        onSubmit: async (data) => {
            console.log(data);

            if (editData == null) {
                dispatch(addCompany(data));

            } else {
                // data['userId']=editUser._id;
                dispatch(editCompany(data));

            }
            // setLoading(true);
            // setloadingIcon("pi pi-spin pi-spinner");
            // if (editable === true) {

            //     data["userId"] = UsersRowData;
            //     const res = await dispatch(handlePatchRequest(data, "api/v1/user/", true, true));

            //     if (res?.status === 200) {
            //         await getUserData();
            //         formik.resetForm();
            //         onHide();
            //     }
            // } else {
            //     const res = await dispatch(handlePostRequest(data, "api/v1/user/", true, true));

            //     if (res?.status === 200) {
            //         await getUserData();
            //         formik.resetForm();
            //         onHide();
            //     }
            // }
            // setLoading(false);
            // setloadingIcon("pi pi-save");
        },
    });


    return (
        <>
            {
                loading === true ? <CustomLoading /> :
                    <div>
                        <form onSubmit={formik.handleSubmit}>
                        <div className="col-12 md:col-6\8">
                            <div className=" p-fluid">
                                <CustomInputField type='text' iden='name' formik={formik}  />
                                <CustomInputField type='text' iden='address' formik={formik}  />
                                <CustomInputField type='dropdown' iden='superAdmin' formik={formik}  options={users} />
                                <Button loading={editData==null? addLoading:editLoading} type='submit' label='Submit' className='p-button-success' />

                                </div>
                            </div>
                    
                        </form>
                    </div>
            }
        </>
    )
}
