import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getCompanyBranches } from '../../../../redux/companies/companies_slice';
import CustomLoading from '../../../components/custom_spinner';

export default function CompanyScreen() {
    const history = useHistory();

    const { id } = useParams();

    const companySlice = useSelector((state) => state.companySlice);
    const { companyBranches, companyBranchesLoading, companyBranchesError } = companySlice;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCompanyBranches(id));

    }, [])

    const actionsBodyTemplate = (rowData) => {
        return <div>
       
            <Button icon="pi pi-box" className="p-button-rounded p-button-primary" onClick={() => {
                history.push("/api/company/budget/" + rowData._id)
            }} aria-label="Budget" />
            {/* <Button icon="pi pi-bookmark" className="p-button-rounded p-button-secondary" aria-label="Bookmark" /> */}

        </div>;
    }

    return (
        <>
            {
                companyBranchesLoading === true ?
                    <CustomLoading /> : companyBranchesError ?
                        <div className='card'>{companyBranchesError}</div>
                        :

                        <div className='card'>
                            <DataTable value={companyBranches} header="Branches" paginator className="p-datatable-gridlines" showGridlines rows={10}
                                dataKey="id" filterDisplay="menu" loading={companyBranchesLoading} responsiveLayout="scroll"
                                emptyMessage="No customers found.">
                                <Column field="name" header="Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} />
                                <Column field="address" header="Address" filter filterPlaceholder="Search by email" style={{ minWidth: '12rem' }} />


                                {/* <Column header="Date" field="createdAt" dataType="date" style={{ minWidth: '10rem' }}
                        filter /> */}
                                <Column header="Actions" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={actionsBodyTemplate} />
                            </DataTable>
                        </div>


            }
        </>
    )
}
