import { InputText } from 'primereact/inputtext'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { addAdminUser, editAdminUser, getAdminRoles, getAdminsUsers, resetAddEditAdminUser } from '../../../../redux/admin_users/admin_users_slice';
import CustomLoading from '../../../components/custom_spinner';





export default function AddEditAdminUserDialog({ onHide, editUser }) {

    const [role, setRole] = useState(null);
    const adminUsers = useSelector((state) => state.adminUsers);
    const {addLoading,editLoading,addError,editError,editSuccess,addSuccess}=adminUsers;
    const { roles, rolesLoading, rolesError } = adminUsers;

    const dispatch = useDispatch();

    const toast = useRef(null);

    const statusList=[
            {
                'code':'active',
                'name':'active',
            },
            {
                'code':'block',
                'name':'block',
            },
    ];

    const loadEditData = () => {
        console.log(editUser);
        // formik.setFieldValue("status", editUser['status']);
        formik.setFieldValue("name", editUser['name']);
        formik.setFieldValue("email", editUser['email']);
        formik.setFieldValue("mobile", editUser['mobile']);
        formik.setFieldValue("status", editUser['status']);
        formik.setFieldValue("role", editUser['role']['_id']);

    }


    useEffect(() => {
        if (roles === undefined || roles.length === 0) {
            dispatch(getAdminRoles());
        }
        if (editUser != null) {
            loadEditData();
        }



    }, []);



    useEffect(() => {


        if (addSuccess != undefined) {
            if (addSuccess === true) {
                toast.success({ severity: 'success', summary: 'Success Message', detail: 'User added successfully', life: 3000 });
                formik.resetForm();
                onHide();
                dispatch(getAdminsUsers());


            } else {
                toast.error({ severity: 'error', summary: 'Error Message', detail: addError, life: 3000 });


            }
        }

        if (editSuccess != undefined) {
            if (editSuccess === true) {
                toast.success({ severity: 'success', summary: 'Success Message', detail: 'User updated successfully', life: 3000 });
                formik.resetForm();
                onHide();
                dispatch(getAdminsUsers());


            } else {
                toast.error({ severity: 'error', summary: 'Error Message', detail: editError, life: 3000 });


            }
        }

        return ()=>{
            dispatch(resetAddEditAdminUser());
        }


    }, [addSuccess, addError,editSuccess,editError]);
 

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required.").max(30,'Name should be maximum of 30 length'),
        password: editUser == null ? Yup.string().required("Password is required.").min(8, 'Minimum length should be 8') : Yup.string().min(8, 'Minimum length should be 8'),
        email: Yup.string().email("Invalid email address format").required("Email is required."),
        mobile: Yup.string()
            .required("Mobile number is required.")
            .matches(/^[A-Za-z0-9 ]+$/, "Special Character are not allowed")
            .max(100, "Maximum length 100 allowed")
            .nullable(),
        role: Yup.string().required("Role is required")
    });


 
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            password: "",
            email: "",
            mobile: "",
            role: "",
            status:""
        },

        onSubmit: async (data) => {
            console.log(data);

            if(editUser==null){
                dispatch(addAdminUser(data));

            }else{
                data['userId']=editUser._id;
                dispatch(editAdminUser(data));

            }
            // setLoading(true);
            // setloadingIcon("pi pi-spin pi-spinner");
            // if (editable === true) {

            //     data["userId"] = UsersRowData;
            //     const res = await dispatch(handlePatchRequest(data, "api/v1/user/", true, true));

            //     if (res?.status === 200) {
            //         await getUserData();
            //         formik.resetForm();
            //         onHide();
            //     }
            // } else {
            //     const res = await dispatch(handlePostRequest(data, "api/v1/user/", true, true));

            //     if (res?.status === 200) {
            //         await getUserData();
            //         formik.resetForm();
            //         onHide();
            //     }
            // }
            // setLoading(false);
            // setloadingIcon("pi pi-save");
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <Toast ref={toast} />

            <form onSubmit={formik.handleSubmit}>

                {
                    rolesLoading === true ? <CustomLoading /> :
                        <div className="col-12 md:col-6\8">
                            <div className=" p-fluid">


                                <div className="field">

                                    <label htmlFor="name1">Name</label>
                                    <InputText id="name" name='name' value={formik.values.name} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('name') })} />
                                    {getFormErrorMessage('name')}
                                </div>
                                <div className="field">
                                    <label htmlFor="name1">Password</label>
                                    <InputText id="password" name='password' value={formik.values.password} onChange={formik.handleChange} type="text" className={classNames({ 'p-invalid': isFormFieldValid('password') })} />
                                    {getFormErrorMessage('password')}
                                </div>
                                <div className="field">
                                    <label htmlFor="email1">Email</label>
                                    <InputText id="email1" type="email" name='email'
                                        value={formik.values.email} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('email') })}
                                    />
                                    {getFormErrorMessage('email')}
                                </div>
                                <div className="field">
                                    <label htmlFor="age1">Mobile</label>
                                    <InputText id="age1" type="tel"
                                        name='mobile'
                                        value={formik.values.mobile} onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('mobile') })}

                                    />
                                    {getFormErrorMessage('mobile')}
                                </div>
                                <h1>
                                </h1>
                                <div className="field">
                                    <label htmlFor="age1">Role</label>
                                    <Dropdown optionLabel="role" name='role' optionValue='_id' value={formik.values.role} options={roles} onChange={formik.handleChange} placeholder="Select a role"
                                        className={classNames({ 'p-invalid': isFormFieldValid('role') })}
                                    />
                                    {getFormErrorMessage('role')}
                                </div>

                               {editUser!=null&& <div className="field">
                                    <label htmlFor="age1">Status</label>
                                    <Dropdown optionLabel="name" optionValue='code' name='status' value={formik.values.status} options={statusList} onChange={formik.handleChange} placeholder="Select a status"
                                        className={classNames({ 'p-invalid': isFormFieldValid('role') })}
                                    />
                                </div>}
                                <Button loading={editUser==null? addLoading:editLoading} type='submit' label='Submit' className='p-button-success' />

                            </div>


                        </div>}
            </form>

        </>
    )
}
