import Axios from 'axios';
import appUrl from '../../constants/appUrl';
import { axiosApi } from '../../services/axios_api';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const companiesSlice = createSlice({
    name: 'adminUser',
    initialState: {},
    reducers: {
        resetAddEditCompany(state, action) {
            return { ...state, addSuccess: undefined, editSuccess: undefined }
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompaniesList.pending, (state, action) => {
                return { ...state, loading: true }
            })
            .addCase(getCompaniesList.fulfilled, (state, action) => {

                return { ...state, loading: false, data: action.payload }
            })
            .addCase(getCompaniesList.rejected, (state, action) => {

                return {
                    ...state,
                    loading: false,
                    error: action.payload
                }
            });

        builder
            .addCase(addCompany.pending, (state, action) => {
                return { ...state, addLoading: true }
            })
            .addCase(addCompany.fulfilled, (state, action) => {

                return { ...state, addLoading: false, addData: action.payload ,addSuccess:true}
            })
            .addCase(addCompany.rejected, (state, action) => {

                return {
                    ...state,
                    addLoading: false,
                    addError: action.payload,
                    addSuccess:false
                }
            });
        builder
            .addCase(editCompany.pending, (state, action) => {
                return { ...state, editLoading: true }
            })
            .addCase(editCompany.fulfilled, (state, action) => {

                return { ...state, editLoading: false, editData: action.payload,editSuccess:true }
            })
            .addCase(editCompany.rejected, (state, action) => {

                return {
                    ...state, editLoading: false,
                    error: action.payload,
                    editSuccess:false
                }
            });
        builder
            .addCase(getCompanyBranches.pending, (state, action) => {
                return { ...state, companyBranchesLoading: true }
            })
            .addCase(getCompanyBranches.fulfilled, (state, action) => {

                return { ...state, companyBranchesLoading: false, companyBranches: action.payload,rolesSuccess:true }
            })
            .addCase(getCompanyBranches.rejected, (state, action) => {

                return {
                    ...state, companyBranchesLoading: false,
                    companyBranchesError: action.payload,
                    rolesSuccess:false
                }
            });
    },
});

export default companiesSlice.reducer;
export const { resetAddEditCompany } = companiesSlice.actions;


// Thunks
export const getCompaniesList = createAsyncThunk('company/fetch', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.getCompanies);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const getCompanyBranches = createAsyncThunk('company/branches', async (id, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await axiosApi.get(appUrl.baseUrl + appUrl.companyBranches+`?companyId=${id}`);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const addCompany = createAsyncThunk('company/add', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.post(appUrl.baseUrl + appUrl.addCompany, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
export const editCompany = createAsyncThunk('company/edit', async (body, { rejectWithValue, fulfillWithValue }) => {
    try {
        const { data } = await Axios.patch(appUrl.baseUrl + appUrl.editCompany, body);
        return fulfillWithValue(data.data);
    } catch (error) {

        throw rejectWithValue(error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message)

    }

});
