import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { editAdminUser, getAdminsUsers } from '../../../../redux/admin_users/admin_users_slice';
import CustomLoading from '../../../components/custom_spinner';
import CustomInputField from '../../../components/custom_input_field';
import { addSuperAdminUser, editSuperAdmin, getSuperAdminUsers, resetAddEditSuperAdmin } from '../../../../redux/super_admin/super_admin_slice';
import { toast } from 'react-toastify';





export default function AddEditSuperAdminDialog({ onHide, editUser }) {

    const [role, setRole] = useState(null);
    const adminUsers = useSelector((state) => state.superAdminSlice);
    const {addLoading,editLoading,addError,editError,editSuccess,addSuccess}=adminUsers;
    const { roles, rolesLoading, rolesError } = adminUsers;

    const dispatch = useDispatch();


    const statusList=[
            {
                'code':true,
                'name':'active',
            },
            {
                'code':false,
                'name':'block',
            },
    ];

    const loadEditData = () => {
        console.log(editUser);
        // formik.setFieldValue("status", editUser['status']);
        formik.setFieldValue("firstName", editUser['firstName']);
        formik.setFieldValue("lastName", editUser['lastName']);
        formik.setFieldValue("email", editUser['email']);
        
  

    }


    useEffect(() => {
   
        if (editUser != null) {
            loadEditData();
        }



    }, []);



    useEffect(() => {


        if (addSuccess !== undefined) {
            if (addSuccess === true) {
                toast.success("User added successfully")
                formik.resetForm();
                onHide();
                dispatch(getSuperAdminUsers());


            } else {
                toast.error(addError)


            }
        }

        if (editSuccess !== undefined) {
            if (editSuccess === true) {
                toast.success({ severity: 'success', summary: 'Success Message', detail: 'User updated successfully', life: 3000 });
                formik.resetForm();
                onHide();
                dispatch(getSuperAdminUsers());


            } else {
                toast.error({ severity: 'error', summary: 'Error Message', detail: editError, life: 3000 });


            }
        }

        return ()=>{
            dispatch(resetAddEditSuperAdmin());
        }


    }, [addSuccess, addError,editSuccess,editError]);
 

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("First name is required."),
        lastName: Yup.string().required("Last name is required."),
        password:editUser!=null?Yup.string().min(8,"Minimum length 8 is required"): Yup.string().required("Password is required.").min(8,"Minimum length 8 is required"),
        email: Yup.string().email("Invalid email address format").required("Email is required."),
        // mobile: Yup.string()
        //     .required("This field is required.")
        //     .matches(/^[A-Za-z0-9 ]+$/, "Special Character are not allowed")
        //     .max(100, "Maximum length 100 allowed")
        //     .nullable(),
        // role: Yup.string().required("Role is required")
    });


 
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
       
        },

        onSubmit: async (data) => {
            console.log(data);

            if(editUser==null){
                dispatch(addSuperAdminUser(data));

            }else{
                data['userId']=editUser._id;
                dispatch(editSuperAdmin(data));

            }
            // setLoading(true);
            // setloadingIcon("pi pi-spin pi-spinner");
            // if (editable === true) {

            //     data["userId"] = UsersRowData;
            //     const res = await dispatch(handlePatchRequest(data, "api/v1/user/", true, true));

            //     if (res?.status === 200) {
            //         await getUserData();
            //         formik.resetForm();
            //         onHide();
            //     }
            // } else {
            //     const res = await dispatch(handlePostRequest(data, "api/v1/user/", true, true));

            //     if (res?.status === 200) {
            //         await getUserData();
            //         formik.resetForm();
            //         onHide();
            //     }
            // }
            // setLoading(false);
            // setloadingIcon("pi pi-save");
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>

            <form onSubmit={formik.handleSubmit}>

                {
                    rolesLoading === true ? <CustomLoading /> :
                        <div className="col-12 md:col-6\8">
                            <div className=" p-fluid">
                                <CustomInputField type='text' iden='firstName' formik={formik}  />
                                <CustomInputField type='text' iden='lastName' formik={formik}  />
                                <CustomInputField type='email' iden='email' formik={formik}  />
                                <CustomInputField type='password' iden='password' formik={formik}  />


                                <Button loading={editUser==null? addLoading:editLoading} type='submit' label='Submit' className='p-button-success' />

                            </div>


                        </div>}
            </form>

        </>
    )
}
