import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBranchBudget } from '../../../../redux/companies/budget_slice';
import AddEditBudgetDialog from '../components/add_edit_budget_dialog';
import CustomLoading from '../../../components/custom_spinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function BudgetScreen() {

    const {id}=useParams();
    const [editData,setEditData]=useState(null)
    const budgetSlice = useSelector((state) => state.budgetSlice);
   const {budgetLoading,branchBudget,budgetError}=budgetSlice;
   const [editUser,setEditUser]=useState(null);

   const [showAddUserDialog,setAddUserDialog]=useState(false);
   const [dynamicColumns,setDynamicColumns]=useState([]);

   const [data,setData]=useState([]);


   const dispatch = useDispatch();
   const dialogFuncMap = {
    'showAddUserDialog': setAddUserDialog,
 
}
const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

  
}


const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
}

useEffect(() => {
    dispatch(getBranchBudget(id));

}, [])


useEffect(() => {
  
    if(branchBudget!==undefined&&branchBudget.length>0&&branchBudget[0].budget.length>0){
      console.log(branchBudget.length);
      console.log(branchBudget);

      let tempData=[]

      console.log('test',branchBudget[0].budget[0].budgetedUnits);

      var list=[];
      list.push({
        field:'year',
        header:"Year"
    })
      list.push({
        field:'month',
        header:"Month"
    })
  

      let isFirstTime=true;
     for(var item of branchBudget){
       var tempValue={};
      for(var i=0;i<branchBudget[0].budget.length;i++){
        let name1= `${branchBudget[0].budget[i].source.type}budgetedUnits`;
        let name2= `${branchBudget[0].budget[i].source.type}budgetedUnitRate`;
        let temp2={};
        temp2[name1]=item.budget[i].budgetedUnits;
        temp2[name2]=item.budget[i].budgetedUnitRate;
        temp2['reserved1']=item.budget[i].reserved1;
        tempValue={
          ...tempValue,
          ...temp2,
          month:item.month,
          year:item.year,
        
          
        }
    
      if(isFirstTime){
        list.push({
          field:`${branchBudget[0].budget[i].source.type}budgetedUnits`,
          header:`${branchBudget[0].budget[i].source.type} Budgted Units`,
        });
        list.push({
          field:`${branchBudget[0].budget[i].source.type}budgetedUnitRate`,
          header:`${branchBudget[0].budget[i].source.type} cost/${branchBudget[0].budget[i].source.unit}`,
        });
       
       
       
      }
       
       

      }
      tempData.push(tempValue)
      isFirstTime=false;
     } 
     console.log(tempData);
     setData(tempData);
   
      

    console.log('list',list);
   

    setDynamicColumns(list)
    }else{

      setData([])
      setDynamicColumns([])
    }

  
  }, [branchBudget]);

  const getDynamicColumns = dynamicColumns.map((col,i) => {
    return <Column key={col.field} field={col.field} header={col.header} />;
});

const actionsBodyTemplate = (rowData,props) => {
  return <div>
 
      <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary" onClick={() => {
        console.log(branchBudget[props.rowIndex]);
         setEditData(branchBudget[props.rowIndex])
                        onClick('showAddUserDialog')
      }} aria-label="Budget" />
      {/* <Button icon="pi pi-bookmark" className="p-button-rounded p-button-secondary" aria-label="Bookmark" /> */}

  </div>;
}



  return (
    <>
       <Dialog header={editUser==null?'Add budget':'Edit budget'} visible={showAddUserDialog} onHide={() => onHide('showAddUserDialog')}  breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '70vw' }}>
                <AddEditBudgetDialog id={id} editData={editData} onHide={()=>onHide('showAddUserDialog')} />
            </Dialog>
            <div className='card'>

            <div className='flex flex-row justify-content-between align-content-center align-items-center pb-3'>
                    <h5>Budget</h5>
                    <Button label='Add new entry' className='p-button-success p-button-rounded p-button-sm' onClick={() =>{
                           setEditData(null);
                        onClick('showAddUserDialog')}} />



                </div>

                {
  budgetLoading===true||branchBudget===undefined?<CustomLoading/>:
  <div>

  { <DataTable value={data} responsiveLayout="scroll" rows={10} paginator >
                    {getDynamicColumns}
                    <Column header="Action"  body={actionsBodyTemplate} />
                </DataTable>}


    </div>
}
            </div>

           
    </>
  )
}
