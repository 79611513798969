const  appUrl={
    // baseUrl:'http://192.168.4.178:3003/',
    fileBaseUrl:'https://api.empath.co.in/',
    baseUrl:'https://api.empath.co.in/api/v1/',
    // fileBaseUrl:'http://192.168.18.36:3002/',
    // baseUrl:'http://192.168.18.36:3002/api/v1/',
    loginUser:'auth/admin',
    getAdminUsers:'hlpAdmin',
    editAdminUser:'',
    addAdminUser:'',
    getRoles:'',
    getCompanies:'company',
    addCompany:'company',
    editCompany:'company',
    companyBranches:'branch',
    addBudget:'hlpBudget',
    editBudget:'hlpBudget',
    getBranchBudget:'hlpBudget',
    getBudgetSources:'hlpBudget/sources',
    getSuperAdmins:'user/allAdmin',
    addSuperAdmins:'user',
    editSuperAdmins:'user',

}
export  default appUrl;